<!-- eslint-disable -->
<template>
    <div class="flex items-center justify-center" style="background-image: url(); background-size: cover; height: 100vh">
        <Alert />
        <div class="w-50-l w-90">
            <div class="tc pb4">
                <img src="../../assets/images/simplebks-logo.png" style="width: 180px" alt="simplebks-logo" />
            </div>

            <div class="pa2" style="background-color: white; border-radius: 5px;">
                <div class="tc pt4 pb2">
                    <img
                        src="../../assets/images/credit-card-new-payment-successful-svgrepo-com.svg"
                        width="100px"
                        alt="payment icon"
                    />
                </div>
                <div class="tc pb3">
                    <h2 class="lh-copy">Your payment for the invoice has been processed</h2>
                    <div class="lh-copy">
                        This invoice was issued through Simplebks. Accounting Made Easy! 💛 For Small Businesses.
                    </div>
                </div>

                <div id="form" class="tc mt3 mb5">
                    Not heard of Simplebks? <a href="https://simplebks.com" class="b">Get started</a>.
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { reactive, onMounted, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert'

export default {
    name: 'PaystackPageCallback',
    components: { Alert },

    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()
        const state = reactive({
            otp: ''
        })

        onBeforeMount(async () => {
            const { reference } = route.params
            if (!reference) {
                router.push({ name: 'PaystackPageConfirmation' })
            } else {
                const payload = { reference }
                await store.dispatch('Invoice/verifyPayment', payload).then(data => {
                    if (data.status) {
                        router.push({ name: 'PaystackPageConfirmation' })
                    }
                })
            }
        })

        onMounted(() => {})

        return { state }
    }
}
</script>

<style scoped></style>
